.descriptionContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
}

.blackBackground{
    background-color: black;
}

.yellowBackground{
    background-color: #ff8b00;
}

.descriptionContainer div{
    flex: 5;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer img{
    margin: 0 auto;
    width: 100%;
}

.textContainer{
    text-align: center;
}

.textContainer p{
    margin: 0 auto;
    font-weight: bold;
    display: inline-block;
    color: white;
}

.spacer{
    flex:1;
}

@media (max-width: 500px) {
    .descriptionContainer{
        flex-direction: column;
    }
}