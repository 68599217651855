body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Toolbar_Toolbar__1B-6d {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ff8b00;;
    display: -webkit-flex;;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar_Toolbar__1B-6d nav {
    height: 100%;
}

.Toolbar_Logo__34_4b {
    height: 80%;
}

@media (max-width: 499px) {
    .Toolbar_DesktopOnly__2izjn {
        display: none;
    }
}
.Logo_Logo__2G7Ti {
    background-color: white;
    padding: 8px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo_Logo__2G7Ti img {
    height: 100%;
}
.NavigationItems_NavigationItems__1eoLQ {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__1eoLQ {
        -webkit-flex-flow: row;
                flex-flow: row;
    }
}
.NavigationItem_NavigationItem__2Y7C3 {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem_NavigationItem__2Y7C3 a {
    color: #8F5C2C;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem_NavigationItem__2Y7C3 a:hover,
.NavigationItem_NavigationItem__2Y7C3 a:active,
.NavigationItem_NavigationItem__2Y7C3 a.NavigationItem_active__1gfe2 {
    color: #40A4C8;
}

@media (min-width: 500px) {
    .NavigationItem_NavigationItem__2Y7C3 {
        margin: 0;
        display: -webkit-flex;
        display: flex;
        height: 100%;
        width: auto;
        -webkit-align-items: center;
                align-items: center;
    }
    
    .NavigationItem_NavigationItem__2Y7C3 a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem_NavigationItem__2Y7C3 a:hover,
    .NavigationItem_NavigationItem__2Y7C3 a:active,
    .NavigationItem_NavigationItem__2Y7C3 a.NavigationItem_active__1gfe2 {
        background-color: #8F5C2C;
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}
.DrawerToggle_DrawerToggle__2LXaB {
    width: 40px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle_DrawerToggle__2LXaB div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 500px) {
    .DrawerToggle_DrawerToggle__2LXaB {
        display: none;
    }
}
.SideDrawer_SideDrawer__38h0K {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 500px) {
    .SideDrawer_SideDrawer__38h0K {
        display: none;
    }
}

.SideDrawer_Open__6YvBE {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.SideDrawer_Close__2emmZ {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.SideDrawer_Logo__3T4T3 {
    height: 11%;
    margin-bottom: 32px;
}
.Backdrop_Backdrop__1WMuR {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.Footer_Footer__8gTa3{
    width: 100%;
    background-color: black;
    color: white;
    padding: 20px 0px;
}

.Footer_Footer__8gTa3 a{
    color: white;
}
.Layout_Content__PbozZ {
    margin-top: 72px;
}
.DescriptionContainer_descriptionContainer__DqGup{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
}

.DescriptionContainer_blackBackground__1KLTV{
    background-color: black;
}

.DescriptionContainer_yellowBackground__1W_el{
    background-color: #ff8b00;
}

.DescriptionContainer_descriptionContainer__DqGup div{
    -webkit-flex: 5 1;
            flex: 5 1;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.DescriptionContainer_imageContainer__1jd3h img{
    margin: 0 auto;
    width: 100%;
}

.DescriptionContainer_textContainer__L2wtB{
    text-align: center;
}

.DescriptionContainer_textContainer__L2wtB p{
    margin: 0 auto;
    font-weight: bold;
    display: inline-block;
    color: white;
}

.DescriptionContainer_spacer__1YfDv{
    -webkit-flex:1 1;
            flex:1 1;
}

@media (max-width: 500px) {
    .DescriptionContainer_descriptionContainer__DqGup{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
.HomePage_HomePage__1svCz img{
    width: 80%;
}

/* @media (min-width: 1000px) {
    .HomePage img{
        width: 1000px;
    }
} */
.Input_Input__Gm83U {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Input_Label__1MW54 {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.Input_InputElement__21KL4 {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.Input_InputElement__21KL4:focus {
    outline: none;
    background-color: #ccc;
}

.Input_Invalid__9Wmik {
    border: 1px solid red;
    background-color: #FDA49A;
}
.Button_Button__3sgDz {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

.Button_Button__3sgDz:first-of-type {
    margin-left: 0;
    padding-left: 0;
}

.Button_Button__3sgDz:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Button_Success__19Tri {
    color: #5C9210;
}

.Button_Danger__3QVPS {
    color: #944317;
}
.Auth_Auth__3_Hz7 {
    margin: 20px auto;
    width: 80%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}

@media (min-width: 600px) {
    .Auth_Auth__3_Hz7 {
        width: 500px;
    }
}
.AboutUsPage_AboutUsPage__1Cqmc {
    margin: 20px auto;
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}

.AboutUsPage_AboutUsPage__1Cqmc img{
    width: 300px;
    height: 240px;
}

.AboutUsPage_SharedImageP__2lh2Y{
    display: inline-block;
    vertical-align: top;
    text-align: start;
}

.AboutUsPage_HalfWidth__ynZEF{
    width: 45%;
}

.AboutUsPage_DivRow__3tDO-{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.AboutUsPage_ProfileDescription__fQTYX{
    font-size: 12px;
    margin: 0;
    -webkit-transform: translateX(-70px);
            transform: translateX(-70px);
}


@media (max-width: 680px) {
    .AboutUsPage_DivRow__3tDO- {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .AboutUsPage_ProfileDescription__fQTYX{
        -webkit-transform: translateX(-15px);
                transform: translateX(-15px);
    }

    .AboutUsPage_AboutUsPage__1Cqmc img{
        width: 200px;
        height: 160px;
    }
    
}
