.AboutUsPage {
    margin: 20px auto;
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}

.AboutUsPage img{
    width: 300px;
    height: 240px;
}

.SharedImageP{
    display: inline-block;
    vertical-align: top;
    text-align: start;
}

.HalfWidth{
    width: 45%;
}

.DivRow{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProfileDescription{
    font-size: 12px;
    margin: 0;
    transform: translateX(-70px);
}


@media (max-width: 680px) {
    .DivRow {
        flex-direction: column;
    }

    .ProfileDescription{
        transform: translateX(-15px);
    }

    .AboutUsPage img{
        width: 200px;
        height: 160px;
    }
    
}